import React from "react";
import { Avatar, Col, Row, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Draggable } from "react-beautiful-dnd";
import TaskItemPriorityBadge from "../TaskItemPriorityBadge";
import { ReactComponent as NewTaskIcon } from "../../../assets/images/icons/new_task.svg";
import { ReactComponent as BugIcon } from "../../../assets/images/icons/bug.svg";
import { useDispatch } from "react-redux";
import { removeTask } from "../../../store/actions/task";
import { fetchProjectDetail } from "../../../store/actions/project";
import moment from 'moment';
const TaskItem = ({ listTaskDetailItem, index, onClick, ...props }) => {
  const dispatch = useDispatch();
  return (
    <Draggable draggableId={listTaskDetailItem.taskId.toString()} index={index}>
      {(provided) => {
        return (
          <div
            className="bg-white p-2 mt-2 shadow rounded"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="d-flex justify-content-between">
              <div className="mt-2">
                {listTaskDetailItem.taskTypeDetail.id === 1 && (
                  <div className="d-flex">
                  <BugIcon className="mr-1 mt-1" /> <b>Bugs</b>
                  </div>
                )}
                {listTaskDetailItem.taskTypeDetail.id === 2 && (
                    <div className="d-flex">
                    <NewTaskIcon className="mr-1 mt-1" /> <b>Task</b> 
                  </div>
                )}
                
              </div>
              <div className="text-right mt-2">
                <button
                  className="btn btn-danger px-2 py-0"
                  style={{ position: "relative", zIndex: 10 }}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      removeTask({ taskId: listTaskDetailItem.taskId }, () =>
                        dispatch(
                          fetchProjectDetail(
                            listTaskDetailItem.projectId,
                            () => {
                              // props.onCancel();
                            }
                          )
                        )
                      )
                    );
                  }}
                >
                  <i className="fa fa-close"></i>
                </button>
              </div>
            </div>
            <div
              className="w-full bg-white rounded py-3 px-2 mt-1 "
              onClick={onClick}
            >
              <Row>
                <Col span={18}>
                  {listTaskDetailItem.taskName && (
                    <div className="mb-2">{listTaskDetailItem.taskName}</div>
                  )}
                  {!listTaskDetailItem.taskName && (
                    <div className="mb-2 text-gray-400">Unnamed</div>
                  )}

                  <div className="flex justify-start items-center">
                    <Tooltip
                      title={
                        listTaskDetailItem.taskTypeDetail.taskType
                          .charAt(0)
                          .toUpperCase() +
                        listTaskDetailItem.taskTypeDetail.taskType.slice(1)
                      }
                      placement="bottom"
                    >
                      {/* {listTaskDetailItem.taskTypeDetail.id === 1 && (
                        <BugIcon className="mr-1" />
                      )}
                      {listTaskDetailItem.taskTypeDetail.id === 2 && (
                        <NewTaskIcon className="mr-1" />
                      )} */}
                    </Tooltip>

                    <TaskItemPriorityBadge
                      priorityTask={listTaskDetailItem.priorityTask}
                    />
                  </div>
                </Col>
                <Col span={6}>
                  <div className="h-full w-full flex justify-end items-end">
                    {!listTaskDetailItem.assigness.length && (
                      <Tooltip title="Unassigned" placement="top">
                        <Avatar size="small" icon={<UserOutlined />} />
                      </Tooltip>
                    )}
                    {listTaskDetailItem.assigness && (
                      <Avatar.Group size="small" maxCount={2}>
                        {listTaskDetailItem.assigness.map((assignee) => {
                          return (
                            <Tooltip
                              key={assignee.id}
                              title={assignee.name}
                              placement="top"
                            >
                              <Avatar src={assignee.avatar} />
                            </Tooltip>
                          );
                        })}
                      </Avatar.Group>
                    )}
                  </div>
                

                </Col>
                <div className="w-100">Deadline: {listTaskDetailItem.deadLine}</div>
              </Row>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default TaskItem;
